'<template>
  <div class="modalPopup print_modal ledger_print_modal" v-if="modalClose">
    <div class="modal_header">
      <h2 class="title">
        제품 원장
      </h2>
      <button type="button" class="modal_close" @click="isModalClose"></button>
      <ul class="option">
        <li>
          {{ `${startDate} ~ ${endDate}` }}
        </li>
        <li>
          <h6>
            {{ checkPage }}
          </h6>
          <span v-for="item in include_list" :key="item.id">
            {{ findInfoFromId(product, item.id).name }}</span
          >
          <!-- <span>
            {{
              include_list > 1
                ? findInfoFromId(companys, include_list[0]).name
                : `${
                    findInfoFromId(companys, include_list[0]).name
                  }외 ${include_list.length - 1}개`
            }}</span
          > -->
        </li>
      </ul>
    </div>
    <div class="modal_body">
      <div class="mes_tbl_wrap product_print_table">
        <table class="mes_tbl">
          <colgroup
            :class="{ ledger: !isFilterTable, ledger_filter: isFilterTable }"
          >
            <col v-for="(n, index) in isFilterTable ? 10 : 8" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th v-show="isFilterTable">합계</th>
              <th>일자</th>
              <th>입/출고</th>
              <th>제품명</th>
              <th>납품처</th>
              <th>공정번호(Lot)</th>
              <th>사용공정(Lot)</th>
              <th>입고량(단위)</th>
              <th>출고량(단위)</th>
              <th v-show="isFilterTable">재고(단위)</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(ledger, index) in display_arr"
              :key="ledger.id"
              :class="{
                day: ledger.sum_date == '일 합계',
                month: ledger.sum_date == '월 합계',
                default: ledger.sum_date == '전 재고',
              }"
              v-show="isFilterTable || ledger.sum_date == undefined"
            >
              <!-- 합계 -->
              <td v-show="isFilterTable">{{ ledger.sum_date }}</td>
              <td>
                {{
                  ledger.sum_date == '월 합계'
                    ? ledger.input_date.substr(0, 7)
                    : ledger.input_date
                }}
              </td>
              <td class="ellipsis">
                {{
                  findInfoFromId(
                    productInOutType,
                    ledger.product_in_out_type_id,
                  ).name
                }}
              </td>
              <td class="text_left ellipsis">{{ ledger.product_name }}</td>

              <td class="text_left">
                {{
                  ledger.sales_company != undefined
                    ? ledger.sales_company
                    : ledger.company_name
                }}
              </td>
              <td>
                {{
                  ledger.barcode_num == undefined
                    ? ''
                    : `L${ledger.barcode_num}`
                }}
                <!-- <button
                  class="btn_tbl"
                  v-show="
                    ledger.barcode_num !== undefined &&
                      ledger.barcode_num !== null
                  "
                  @click="trackingLotNum(ledger.barcode_num)"
                >
                  추적
                </button> -->
              </td>

              <td>
                {{
                  ledger.output_lot_number != undefined
                    ? `L${ledger.output_lot_number}`
                    : ''
                }}
                <!-- <button
                  class="btn_tbl"
                  v-show="ledger.output_lot_number != undefined"
                  @click="trackingLotNum(ledger.output_lot_number)"
                >
                  추적
                </button> -->
              </td>
              <!-- 입고량 -->
              <td class="text_right">
                {{
                  ledger.sum_date == '일 합계' || ledger.sum_date == '월 합계'
                    ? ledger.in_quantity
                    : ['process in', 'return in'].includes(
                        findInfoFromId(
                          productInOutType,
                          ledger.product_in_out_type_id,
                        ).detail,
                      )
                    ? ledger.quantity
                    : '' | makeCommaNotZero
                }}
                {{
                  (ledger.sum_date == '일 합계' || ledger.sum_date == '월 합계'
                    ? $makeComma(ledger.in_quantity)
                    : ['process in', 'return in'].includes(
                        findInfoFromId(
                          productInOutType,
                          ledger.product_in_out_type_id,
                        ).detail,
                      )
                    ? ledger.quantity
                    : '') == ''
                    ? ''
                    : ledger.sum_date == '일 합계' ||
                      ledger.sum_date == '월 합계'
                    ? ''
                    : `(${getUnitName(index)})`
                }}
              </td>
              <td class="text_right">
                {{
                  ledger.sum_date == '일 합계' || ledger.sum_date == '월 합계'
                    ? ledger.out_quantity
                    : ['using out', 'sales out'].includes(
                        findInfoFromId(
                          productInOutType,
                          ledger.product_in_out_type_id,
                        ).detail,
                      )
                    ? ledger.quantity
                    : '' | makeCommaNotZero
                }}{{
                  (ledger.sum_date == '일 합계' || ledger.sum_date == '월 합계'
                    ? ledger.out_quantity
                    : ['using out', 'sales out'].includes(
                        findInfoFromId(
                          productInOutType,
                          ledger.product_in_out_type_id,
                        ).detail,
                      )
                    ? ledger.quantity
                    : '') == ''
                    ? ''
                    : ledger.sum_date == '일 합계' ||
                      ledger.sum_date == '월 합계'
                    ? ''
                    : `(${getUnitName(index)})`
                }}
              </td>
              <td v-show="isFilterTable" class="text_right">
                {{
                  ledger.sum_date == '일 합계' || ledger.sum_date == '월 합계'
                    ? $makeComma(Number(ledger.curr_stock))
                    : `${$makeComma(Number(ledger.curr_stock))}(${getUnitName(
                        index,
                      )})`
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-show="false" class="page_wrap">
        <div class="page" v-for="(n, index) in devidePage" :key="index">
          <div class="modal_header">
            <p class="times">
              {{ str_time }}
            </p>
            <h2 class="title">
              제품 원장
            </h2>
            <p class="page_num">
              page: {{ `${index + 1}/${devidePage.length}` }}
            </p>
            <button
              type="button"
              class="modal_close"
              @click="isModalClose"
            ></button>
            <ul class="option">
              <li>
                {{ `(${startDate} ~ ${endDate})` }}
              </li>
              <li>
                <h6>
                  {{ checkPage }}
                </h6>
                <!-- : checkPage == '제품' -->
                <span v-for="item in include_list" :key="item.id">
                  {{ findInfoFromId(product, item.id).name }}</span
                >
                <!-- <span>
                  {{
                    include_list > 1
                      ? findInfoFromId(companys, include_list[0]).name
                      : `${
                          findInfoFromId(companys, include_list[0]).name
                        }외 ${include_list.length - 1}개`
                  }}</span
                > -->
              </li>
            </ul>
          </div>
          <div class="mes_tbl_wrap product_print_table">
            <table class="mes_tbl">
              <colgroup
                :class="{
                  ledger: !isFilterTable,
                  ledger_filter: isFilterTable,
                }"
              >
                <col
                  v-for="(n, index) in isFilterTable ? 10 : 8"
                  :key="index"
                />
              </colgroup>
              <thead>
                <tr>
                  <th v-show="isFilterTable">합계</th>
                  <th>일자</th>
                  <th>입/출고</th>
                  <th>제품명</th>
                  <th>납품처</th>
                  <th>공정번호(Lot)</th>
                  <th>사용공정(Lot)</th>
                  <th>입고량(단위)</th>
                  <th>출고량(단위)</th>
                  <th v-show="isFilterTable">재고(단위)</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(ledger, index2) in devidePage[index]"
                  :key="index2"
                  :class="{
                    day: ledger.sum_date == '일 합계',
                    month: ledger.sum_date == '월 합계',
                    default: ledger.sum_date == '전 재고',
                  }"
                  v-show="isFilterTable || ledger.sum_date == undefined"
                >
                  <!-- 합계 -->
                  <td v-show="isFilterTable">{{ ledger.sum_date }}</td>
                  <td>
                    {{
                      ledger.sum_date == '월 합계'
                        ? ledger.input_date.substr(6, 7)
                        : ledger.input_date.substr(5, 7)
                    }}
                  </td>
                  <td>
                    {{
                      findInfoFromId(
                        productInOutType,
                        ledger.product_in_out_type_id,
                      ).name
                    }}
                  </td>
                  <td class="text_left ellipsis">{{ ledger.product_name }}</td>

                  <td class="text_left ellipsis">
                    {{
                      ledger.sales_company != undefined
                        ? ledger.sales_company
                        : ledger.company_name
                    }}
                  </td>
                  <td>
                    {{
                      ledger.barcode_num == undefined
                        ? ''
                        : `L${ledger.barcode_num}`
                    }}
                    <!-- <button
                      class="btn_tbl"
                      v-show="
                        ledger.barcode_num !== undefined &&
                          ledger.barcode_num !== null
                      "
                      @click="trackingLotNum(ledger.barcode_num)"
                    >
                      추적
                    </button> -->
                  </td>

                  <td>
                    {{
                      ledger.output_lot_number != undefined
                        ? `L${ledger.output_lot_number}`
                        : ''
                    }}
                    <!-- <button
                      class="btn_tbl"
                      v-show="ledger.output_lot_number != undefined"
                      @click="trackingLotNum(ledger.output_lot_number)"
                    >
                      추적
                    </button> -->
                  </td>
                  <!-- 입고량 -->
                  <td class="text_right">
                    {{
                      ledger.sum_date == '일 합계' ||
                      ledger.sum_date == '월 합계'
                        ? ledger.in_quantity
                        : ['process in', 'return in'].includes(
                            findInfoFromId(
                              productInOutType,
                              ledger.product_in_out_type_id,
                            ).detail,
                          )
                        ? ledger.quantity
                        : '' | makeCommaNotZero
                    }}
                    {{
                      (ledger.sum_date == '일 합계' ||
                      ledger.sum_date == '월 합계'
                        ? $makeComma(ledger.in_quantity)
                        : ['process in', 'return in'].includes(
                            findInfoFromId(
                              productInOutType,
                              ledger.product_in_out_type_id,
                            ).detail,
                          )
                        ? ledger.quantity
                        : '') == ''
                        ? ''
                        : ledger.sum_date == '일 합계' ||
                          ledger.sum_date == '월 합계'
                        ? ''
                        : `(${getUnit(index, index2)})`
                    }}
                  </td>
                  <td class="text_right">
                    {{
                      ledger.sum_date == '일 합계' ||
                      ledger.sum_date == '월 합계'
                        ? ledger.out_quantity
                        : ['using out', 'sales out'].includes(
                            findInfoFromId(
                              productInOutType,
                              ledger.product_in_out_type_id,
                            ).detail,
                          )
                        ? ledger.quantity
                        : '' | makeCommaNotZero
                    }}{{
                      (ledger.sum_date == '일 합계' ||
                      ledger.sum_date == '월 합계'
                        ? ledger.out_quantity
                        : ['using out', 'sales out'].includes(
                            findInfoFromId(
                              productInOutType,
                              ledger.product_in_out_type_id,
                            ).detail,
                          )
                        ? ledger.quantity
                        : '') == ''
                        ? ''
                        : ledger.sum_date == '일 합계' ||
                          ledger.sum_date == '월 합계'
                        ? ''
                        : `(${getUnit(index, index2)})`
                    }}
                  </td>
                  <td v-show="isFilterTable" class="text_right">
                    {{
                      ledger.sum_date == '일 합계' ||
                      ledger.sum_date == '월 합계'
                        ? $makeComma(Number(ledger.curr_stock))
                        : `${$makeComma(Number(ledger.curr_stock))}(${getUnit(
                            index,
                            index2,
                          )})`
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal_footer">
      <button type="button" class="btn_sub2 btn_print" @click="handlerPrint">
        <i class="ico_print"></i>출력하기
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FETCH_MIXIN from '@/mixins/fetch';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
import { yyyymmdd } from '@/utils/func';
import { format년월일요일시분초 } from '@/utils/filters';

export default {
  props: ['include_list', 'startDate', 'endDate', 'filterCheck'],
  mixins: [FETCH_MIXIN, DRAG_MODAL_MIXIN],
  data() {
    return {
      modalClose: true,
      checkList: [],
      checkPage: '제품',
      str_time: null,
    };
  },
  computed: {
    ...mapGetters({
      companys: 'getCompany',
      product: 'getProduct',
      material: 'getMaterial',
      filterArray: 'getFilterArrayFromProductLedger' /* filter radiobox */,
      productLedgerList: 'getProductLedgerListFromProductLedger',
      productInOutType: 'getProductInOutType',
      unitCodes: 'getUnitCodes',
      resource_types: 'getResourceTypeNotSort',
      total_stock: 'getProductTotalStock',
      radioList: 'getCheckedRadioFromProductLedger',
      checkBoxList: 'getCheckedBoxFromProductLedger',
    }),

    convertCheck() {
      let clone = this.lodash.clonedeep(this.filterCheck);
      console.log(this.filterCheck);
      clone.splice(0, 1);
      clone = clone.map(x => {
        x = this.findInfoFromId(this.resource_types, x).name;
        return x;
      });
      let cloneList = clone.toString().replace(/,/gi, ', ');
      return cloneList;
    },

    isFilterTable() {
      if (this.radioList.check_include && this.filterArray.length == 1) {
        return true;
      } else {
        return false;
      }
    },
    group_day() {
      let clone = this.lodash.clonedeep(this.productLedgerList);

      if (clone == undefined) {
        return;
      }
      if (this.filterCheck.length > 0) {
        clone = clone.filter(x =>
          this.filterCheck.includes(
            this.findInfoFromId(this.product, x.product_id).resource_type_id,
          ),
        );
      }

      if (this.filterArray.length > 0) {
        const check = this.filterArray.map(x => x.id);
        if (this.radioList.check_include) {
          clone = this.productLedgerList.filter(x =>
            check.includes(x.product_id),
          );
        } else if (this.radioList.check_exclude) {
          clone = this.productLedgerList.filter(
            x => !check.includes(x.product_id),
          );
        } else if (this.radioList.check_all) {
          clone;
        } else {
          return [];
        }
      }

      const obj = clone
        .sort(
          (a, b) =>
            Number(
              a.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ) -
            Number(
              b.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ),
        )
        .reduce(function(rv, x) {
          (rv[x['input_date']] = rv[x['input_date']] || []).push(x);
          return rv;
        }, {});
      let arr = [];
      let count = 100000;
      const reducer = (accumulator, currentValue) =>
        accumulator + Number(currentValue.toFixed(6));
      for (let k in obj) {
        arr.push({
          date: k,
          month: k.substr(0, 7),
          element: obj[k],
        });

        const objInTemp = obj[k]
          .filter(
            x =>
              x.product_in_out_type_id ==
                this.productInOutType.find(x => x.detail == 'process in').id ||
              x.product_in_out_type_id ==
                this.productInOutType.find(x => x.detail == 'return in').id,
          )
          .map(x => x.quantity);

        const objOutTemp = obj[k]
          .filter(
            x =>
              x.product_in_out_type_id ==
                this.productInOutType.find(x => x.detail == 'using out').id ||
              x.product_in_out_type_id ==
                this.productInOutType.find(x => x.detail == 'sales out').id,
          )
          .map(x => x.quantity);

        const sumInQuantity = Number(objInTemp.reduce(reducer, 0).toFixed(6));
        const sumOutQuantity = Number(objOutTemp.reduce(reducer, 0).toFixed(6));
        obj[k].push({
          sum_date: '일 합계',
          input_date: k,
          id: count++,
          in_quantity: sumInQuantity,
          out_quantity: sumOutQuantity,
        });
      }

      return arr;
    },
    group_month() {
      const obj = this.group_day.reduce(function(rv, x) {
        (rv[x['month']] = rv[x['month']] || []).push(x);
        return rv;
      }, {});
      let arr = [];
      let count = 9999999;
      for (let k in obj) {
        arr.push({
          date: k,
          element: obj[k],
        });
        const day_list = obj[k]
          .map(x => x.element)
          .reduce((a, b) => a.concat(b))
          .filter(x => x.sum_date !== undefined);
        const sumAllInQuantity = day_list
          .map(x => x.in_quantity)
          .reduce((a, b) => this.$decimalAdd(a, b));
        const sumAllOutQuantity = day_list
          .map(x => x.out_quantity)
          .reduce((a, b) => this.$decimalAdd(a, b));
        obj[k].push({
          element: {
            sum_date: '월 합계',
            input_date: k + '-00',
            id: count++,
            in_quantity: sumAllInQuantity,
            out_quantity: sumAllOutQuantity,
          },
        });
      }
      return arr;
    },
    display_arr() {
      const temp = this.group_month.map(x => x.element);
      let list_ = [];

      temp.forEach(el => {
        el.forEach(x => {
          list_ = list_.concat(x.element);
        });
      });

      let reverse = list_.reverse();
      let curr_stock_temp = this.lodash.clonedeep(this.product_stock);
      reverse.forEach(x => {
        if (x.sum_date == undefined) {
          if (
            x.product_in_out_type_id ==
              this.productInOutType.find(x => x.detail == 'process in').id ||
            x.product_in_out_type_id ==
              this.productInOutType.find(x => x.detail == 'return in').id
          ) {
            x.curr_stock = Number(Number(curr_stock_temp).toFixed(3));
            curr_stock_temp -= x.quantity;
          } else {
            x.curr_stock = Number(Number(curr_stock_temp).toFixed(3));
            curr_stock_temp += x.quantity;
          }
        } else {
          x.curr_stock = Number(Number(curr_stock_temp).toFixed(3));
        }
      });

      reverse.push({
        curr_stock: Number(Number(curr_stock_temp).toFixed(3)),
        input_date: this.startDate,
        sum_date: '전 재고',
      });
      // reverse.forEach(element => {
      //   element.curr_stock = makeCommaNotZero(element.curr_stock);
      //   element.quantity = makeCommaNotZero(element.quantity);
      //   element.in_quantity = makeCommaNotZero(element.in_quantity);
      //   element.out_quantity = makeCommaNotZero(element.quantity);

      // });
      return reverse
        .reverse()
        .filter(
          x =>
            Number(x.input_date.replace(/-/gi, '')) <=
            Number(this.endDate.replace(/-/gi, '')),
        );
    },
    product_stock() {
      if (this.isFilterTable) {
        if (
          this.total_stock.find(x => x.id == this.filterArray[0].id) !=
          undefined
        ) {
          return this.total_stock.find(x => x.id == this.filterArray[0].id)
            .stock_quantity;
        } else return 0;
      } else {
        return 0;
      }
    },
    group_product() {
      let clone = this.lodash.clonedeep(this.display_arr);
      const obj = clone.reduce(function(rv, x) {
        (rv[x['sales_company']] = rv[x['sales_company']] || []).push(x);
        return rv;
      }, {});
      console.log(obj);
      let list_ = [];
      for (let k in obj) {
        if (k != 'undefined' && k != 'null') {
          list_.push({
            sales_company: k,
            count: obj[k].length,
          });
        }
      }
      return list_.sort((a, b) => b.count - a.count);
    },
    devidePage() {
      let clone = this.lodash.clonedeep(this.display_arr);
      let arr = [];
      if (!this.isFilterTable) {
        clone = clone.filter(
          x =>
            x.sum_date != '일 합계' &&
            x.sum_date != '월 합계' &&
            x.sum_date != '전 재고',
        );
      }
      clone.forEach((el, index) => {
        if (index % 23 == 0) {
          arr.push(clone.slice(index, index + 23));
        }
      });
      return arr;
    },
  },
  methods: {
    isModalClose() {
      this.$emit('onclose');
    },
    getUnitName(index) {
      if (
        this.display_arr[index] != undefined &&
        this.display_arr[index].product_in_out_type_id != undefined
      ) {
        const UnitTemp = this.display_arr[index].unit_id;
        if (UnitTemp !== undefined) {
          const findUId = this.findInfoFromId(this.unitCodes, UnitTemp);
          return findUId != '' ? findUId.name : '';
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
    getUnit(index, index2) {
      if (
        this.devidePage[index][index2] != undefined &&
        this.devidePage[index][index2].product_in_out_type_id != undefined
      ) {
        const UnitTemp = this.devidePage[index][index2].unit_id;
        if (UnitTemp !== undefined) {
          const findUId = this.findInfoFromId(this.unitCodes, UnitTemp);
          return findUId != '' ? findUId.name : '';
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
    handlerPrint() {
      const app = document.querySelector('#app');
      const ledgerHeader = document.querySelector(
        '.ledger_print_modal .modal_header',
      );
      const ledgerTable = document.querySelector(
        '.ledger_print_modal .modal_body .page_wrap',
      );
      ledgerTable.style.display = 'block';

      const printDiv = document.createElement('div');
      let ledgerHeaderClone = ledgerHeader.cloneNode(true);
      let ledgerTableClone = ledgerTable.cloneNode(true);
      // let ledgerTrClone = ledgerTableClone.querySelectorAll('tr');
      // if (this.isFilterTable) {
      //   ledgerTrClone.forEach(el => {
      //     let ledgerTd = el.querySelectorAll('td');
      //     ledgerTd.forEach((el2, index) => {
      //       index == 1 ? (el2.innerText = el2.innerText.substr(6, 7)) : '';
      //     });
      //   });
      // } else {
      //   ledgerTrClone.forEach(el => {
      //     let ledgerTd = el.querySelectorAll('td');
      //     ledgerTd.forEach((el2, index) => {
      //       index == 0 ? (el2.innerText = el2.innerText.substr(6, 7)) : '';
      //     });
      //   });
      // }
      printDiv.className = 'ledger_print_wrap';
      printDiv.className += ' ledger_print_wrap2';
      printDiv.className += ' ledger_print_wrap4';
      document.body.appendChild(printDiv);
      printDiv.append(ledgerHeaderClone, ledgerTableClone);
      app.style.display = 'none';
      window.print();
      ledgerTable.style.display = 'none';
      app.style.display = 'block';
      document.body.removeChild(printDiv);
    },
    FETCH_PRODUCT_LEDGER() {
      this.$store
        .dispatch('FETCH_PRODUCT_LEDGER', {
          start: this.startDate,
          end: '2500-01-01',
        })
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '제품원장 정보를 불러오는 중 오류가 발생했습니다.',
          );
        })
        .finally(() => {});
    },
  },
  async created() {
    if (this.startDate == null) {
      var date = new Date();
      this.$store.commit('setEndDateToProductLedger', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 7));
      this.$store.commit('setStartDateToProductLedger', yyyymmdd(date2));
    }
    const newDate = new Date();
    this.str_time = format년월일요일시분초(newDate);
    await this.FETCH('FETCH_PRODUCT_IN_OUT_TYPE', '제품 입출고 유형');
    await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    await this.FETCH('FETCH_UNIT', '단위');
    await this.FETCH('FETCH_COMPANY', '납품처');
    await this.FETCH('FETCH_RESOURCE_TYPE', '제품 종류');
    await this.FETCH('FETCH_PRODUCT_TOTAL_STOCK', '제품 재고');

    await this.FETCH_PRODUCT_LEDGER();

    if (this.filterCheck.length < 2) {
      this.filterCheck.push(
        this.resource_types.find(x => x.detail == 'product').id,
      );
    }
  },
};
</script>

<style lang="scss" scoped>
@media print {
  @page {
    // size: A4 !important;
    margin: auto 0 0 !important;
  }
  @page :first {
    margin-top: 0 !important;
  }
}
</style>
