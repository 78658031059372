<template>
  <div class="article">
    <div class="ledger_table tbl_wrap">
      <form>
        <div class="tbl_option">
          <div class="search_opt">
            <div class="input_text">
              <label>조회 기간</label>
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="startDate"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="endDate"
                @change="setEndDate($event)"
              />
            </div>
            <div
              class="checkbox_wrap"
              v-if="resource_types != undefined && resource_types.length > 0"
            >
              <div class="input_checkbox">
                <label
                  for="checkbox1"
                  class="chk_box"
                  :class="{
                    active: filterCheck.includes(
                      resource_types.find(x => x.detail == 'product').id,
                    ),
                  }"
                >
                  <i class="fa fa-check"></i>
                </label>
                <input
                  type="checkbox"
                  id="checkbox1"
                  @click="
                    setCheckedBox(
                      resource_types.find(x => x.detail == 'product').id,
                    )
                  "
                />
                <label for="checkbox1" class="label_txt">제품</label>
              </div>
              <div class="input_checkbox">
                <label
                  for="checkbox2"
                  class="chk_box"
                  :class="{
                    active: filterCheck.includes(
                      resource_types.find(x => x.detail == 'half_product').id,
                    ),
                  }"
                >
                  <i class="fa fa-check"></i>
                </label>
                <input
                  type="checkbox"
                  id="checkbox2"
                  @click="
                    setCheckedBox(
                      resource_types.find(x => x.detail == 'half_product').id,
                    )
                  "
                />
                <label for="checkbox2" class="label_txt">반제품</label>
              </div>
            </div>
            <button class="btn_sub2 btn_print" @click.prevent="openModal">
              <i class="ico_print"></i>출력
            </button>
            <product-print-modal
              v-if="isLedgerModalOpen"
              :checkPage="checkPage"
              :startDate="startDate"
              :endDate="endDate"
              :checkIn="this.radioList.checkIn"
              :include_list="this.filterArray"
              :filterCheck="this.filterCheck"
              @onclose="closeModal"
            ></product-print-modal>
            <div
              class="modal_layer"
              v-if="isLedgerModalOpen"
              @click="closeModal"
            ></div>
            <button
              v-if="filterArray.length > 0"
              class="excel_btn"
              @click.prevent="exportExcel"
            >
              엑셀로 저장
            </button>
          </div>
          <div class="input_radio">
            <div>
              <label
                for="radio1"
                class="label"
                :class="{ active: this.radioList.check_all }"
              ></label>
              <input
                type="radio"
                id="radio1"
                name="search_opt"
                @click="setCheckedRadio('check_all')"
              />
              <label for="radio1" class="label_txt">전체 제품 조회</label>
            </div>
            <div>
              <label
                for="radio2"
                class="label"
                :class="{ active: this.radioList.check_include }"
              ></label>
              <input
                type="radio"
                id="radio2"
                name="search_opt"
                @click="setCheckedRadio('check_include')"
              />
              <label for="radio2" class="label_txt">제품 포함 조회</label>
            </div>
            <div>
              <label
                for="radio3"
                class="label"
                :class="{ active: this.radioList.check_exclude }"
              ></label>
              <input
                type="radio"
                id="radio3"
                name="search_opt"
                @click="setCheckedRadio('check_exclude')"
              />
              <label for="radio3" class="label_txt">제품 제외 조회</label>
            </div>
          </div>
          <div class="ledger_opt" v-show="!this.radioList.check_all">
            <button
              type="button"
              class="btn_ledger"
              @click="showResourceSearch = true"
            >
              {{ this.radioList.checkIn }} 항목 선택
            </button>
            <transition-group
              name="selected"
              mode="in-out"
              tag="ul"
              type="transition"
              class="selected_items_wrap"
              v-if="filterArray.length > 0"
            >
              <li
                class="selected_item"
                v-for="(item, index) in filterArray"
                :key="item.id"
              >
                <span>{{ findInfoFromId(product, item.id).name }}</span>
                <button
                  type="button"
                  class="delete_item"
                  @click="filterArray.splice(index, 1)"
                ></button>
              </li>
            </transition-group>
          </div>
          <resource-search
            v-if="showResourceSearch"
            :filter_type="2"
            :ignore_list="filterArray"
            @onclose="showResourceSearch = false"
            @onselect="selectedRadio($event)"
          ></resource-search>
        </div>
      </form>
      <div class="ledger_info" v-if="isFilterTable">
        <div class="led_info">
          <h5>제품 정보</h5>
          <table>
            <tr>
              <th>제품 정보</th>
              <td>
                {{ filterArray[0].name }}
              </td>
              <th>제품 종류</th>
              <td>
                {{
                  findInfoFromId(
                    resource_types,
                    filterArray[0].resource_type_id,
                  ).name
                }}
              </td>
            </tr>
            <tr>
              <th>현재고(단위)</th>
              <td>
                {{
                  `${
                    findInfoFromId(total_stock, filterArray[0].id) != ''
                      ? $makeComma(
                          findInfoFromId(total_stock, filterArray[0].id)
                            .stock_quantity,
                        )
                      : 0
                  }(${
                    findInfoFromId(unitCodes, filterArray[0].stock_unit_id).name
                  })`
                }}
              </td>
              <th>납품처</th>
              <td>{{ getPrimaryCompanyName(filterArray[0].company_list) }}</td>
            </tr>
            <tr>
              <th>규격</th>
              <td>
                {{ filterArray[0].standard }}
              </td>
              <th>완제품검사 여부</th>
              <td>
                {{ filterArray[0].inspection == true ? '검사' : '생략' }}
              </td>
            </tr>
          </table>
        </div>
        <div class="led_main">
          <h5>주력 납품처</h5>
          <table>
            <tr>
              <th>1위</th>
              <td>
                {{
                  group_product.length > 0 ? group_product[0].sales_company : ''
                }}
              </td>
            </tr>
            <tr>
              <th>2위</th>
              <td>
                {{
                  group_product.length > 1 ? group_product[1].sales_company : ''
                }}
              </td>
            </tr>
            <tr>
              <th>3위</th>
              <td>
                {{
                  group_product.length > 2 ? group_product[2].sales_company : ''
                }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <h6>
        조회수 :
        {{
          display_arr.filter(x => x.sum_date == null || x.sum_date == undefined)
            .length
        }}건
      </h6>
      <div class="mes_tbl_wrap product_print_table">
        <table class="mes_tbl" id="excel_table">
          <colgroup
            :class="{ ledger: !isFilterTable, ledger_filter: isFilterTable }"
          >
            <col v-for="(n, index) in isFilterTable ? 8 : 8" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th :colspan="filterArray.length == 1 ? 10 : 8">
                <h2>제품 원장</h2>
              </th>
            </tr>
            <tr>
              <th>조회기간</th>
              <td :colspan="filterArray.length == 1 ? 9 : 7">
                <span>{{ `${startDate}~${endDate}` }}</span>
              </td>
              <!-- <th
                v-for="(n, index) in filterArray.length == 1 ? 6 : 4"
                :key="index"
              ></th> -->
            </tr>
            <tr>
              <th>
                {{
                  radioList.check_all
                    ? '전체 제품'
                    : radioList.check_include
                    ? '포함 제품'
                    : radioList.check_exclude
                    ? '제외 제품'
                    : ''
                }}
              </th>
              <td
                v-for="(n, index) in filterArray.length == 1 ? 6 : 4"
                :key="index"
              >
                {{ addProduct(index) }}
              </td>
            </tr>
            <tr></tr>
            <tr class="table_title">
              <th v-show="isFilterTable">합계</th>
              <th>일자</th>
              <th>입/출고</th>
              <th>제품명</th>
              <th>납품처</th>
              <th>공정번호(Lot)</th>
              <th>사용공정(Lot)</th>
              <th>입고량(단위)</th>
              <th>출고량(단위)</th>
              <th v-show="isFilterTable">재고(단위)</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(ledger, index) in display_arr"
              :key="ledger.id"
              :class="{
                day: ledger.sum_date == '일 합계',
                month: ledger.sum_date == '월 합계',
                default: ledger.sum_date == '전 재고',
              }"
              v-show="isFilterTable || ledger.sum_date == undefined"
            >
              <!-- 합계 -->
              <td v-show="isFilterTable">
                <center>{{ ledger.sum_date }}</center>
              </td>
              <td>
                <center>
                  {{
                    ledger.sum_date == '월 합계'
                      ? ledger.input_date.substr(0, 7)
                      : ledger.input_date
                  }}
                </center>
              </td>
              <td>
                <center>
                  {{
                    findInfoFromId(
                      productInOutType,
                      ledger.product_in_out_type_id,
                    ).name
                  }}
                </center>
              </td>
              <td class="text_left">{{ ledger.product_name }}</td>

              <td class="text_left">
                {{
                  ledger.sales_company != undefined
                    ? ledger.sales_company
                    : ledger.company_name
                }}
              </td>
              <td>
                <center>
                  {{
                    ledger.barcode_num == undefined
                      ? ''
                      : `L${ledger.barcode_num}`
                  }}
                  <button
                    class="btn_tbl"
                    v-show="
                      ledger.barcode_num !== undefined &&
                        ledger.barcode_num !== null
                    "
                    @click="trackingLotNum(ledger.barcode_num)"
                  >
                    추적
                  </button>
                </center>
              </td>

              <td>
                <center>
                  {{
                    ledger.output_lot_number != undefined
                      ? `L${ledger.output_lot_number}`
                      : ''
                  }}
                  <button
                    class="btn_tbl"
                    v-show="ledger.output_lot_number != undefined"
                    @click="trackingLotNum(ledger.output_lot_number)"
                  >
                    추적
                  </button>
                </center>
              </td>
              <!-- 입고량 -->
              <td class="text_right" id="right">
                {{
                  ledger.sum_date == '일 합계' || ledger.sum_date == '월 합계'
                    ? ledger.in_quantity
                    : ['process in', 'return in'].includes(
                        findInfoFromId(
                          productInOutType,
                          ledger.product_in_out_type_id,
                        ).detail,
                      )
                    ? ledger.quantity
                    : '' | makeCommaNotZero
                }}
                {{
                  (ledger.sum_date == '일 합계' || ledger.sum_date == '월 합계'
                    ? $makeComma(ledger.in_quantity)
                    : ['process in', 'return in'].includes(
                        findInfoFromId(
                          productInOutType,
                          ledger.product_in_out_type_id,
                        ).detail,
                      )
                    ? ledger.quantity
                    : '') == ''
                    ? ''
                    : ledger.sum_date == '일 합계' ||
                      ledger.sum_date == '월 합계'
                    ? ''
                    : `(${getUnitName(index)})`
                }}
              </td>
              <td class="text_right" id="right">
                {{
                  ledger.sum_date == '일 합계' || ledger.sum_date == '월 합계'
                    ? ledger.out_quantity
                    : ['using out', 'sales out'].includes(
                        findInfoFromId(
                          productInOutType,
                          ledger.product_in_out_type_id,
                        ).detail,
                      )
                    ? ledger.quantity
                    : '' | makeCommaNotZero
                }}{{
                  (ledger.sum_date == '일 합계' || ledger.sum_date == '월 합계'
                    ? ledger.out_quantity
                    : ['using out', 'sales out'].includes(
                        findInfoFromId(
                          productInOutType,
                          ledger.product_in_out_type_id,
                        ).detail,
                      )
                    ? ledger.quantity
                    : '') == ''
                    ? ''
                    : ledger.sum_date == '일 합계' ||
                      ledger.sum_date == '월 합계'
                    ? ''
                    : `(${getUnitName(index)})`
                }}
              </td>
              <td v-show="isFilterTable" class="text_right" id="right">
                {{
                  ledger.sum_date == '일 합계' || ledger.sum_date == '월 합계'
                    ? $makeComma(ledger.curr_stock)
                    : `${$makeComma(ledger.curr_stock)}(${getUnitName(index)})`
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import fetchMixin from '@/mixins/fetch';
import modalMixin from '@/mixins/modal';
import routes from '@/routes/routes';
import ResourceSearch from '@/layouts/components/search-popup/ResourceSearch';
import ProductPrintModal from '@/layouts/components/ProductPrintModal';
// import { makeCommaNotZero } from '@/utils/filters';

export default {
  mixins: [fetchMixin, modalMixin],
  components: {
    ResourceSearch,
    // LedgerPrintModal,
    ProductPrintModal,
  },
  data() {
    return {
      isLedgerModalOpen: false,
      checkPage: '제품',
      showResourceSearch: false,
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
      delete_btn: true,
    };
  },
  computed: {
    ...mapGetters({
      startDate: 'getStartDateFromProductLedger',
      endDate: 'getEndDateFromProductLedger',
      radioList: 'getCheckedRadioFromProductLedger',
      checkBoxList: 'getCheckedBoxFromProductLedger',
      productLedgerList: 'getProductLedgerListFromProductLedger',
      productInOutType: 'getProductInOutType',
      filterArray: 'getFilterArrayFromProductLedger' /* filter radiobox */,
      filterCheck: 'getFilterCheckFromProductLedger' /* filter checkbox */,
      product: 'getProduct',
      unitCodes: 'getUnitCodes',
      companys: 'getCompany',
      resource_types: 'getResourceTypeNotSort',
      total_stock: 'getProductTotalStock',
    }),
    isFilterTable() {
      if (this.radioList.check_include && this.filterArray.length == 1) {
        return true;
      } else {
        return false;
      }
    },
    group_day() {
      let clone = this.lodash.clonedeep(this.productLedgerList);

      if (clone == undefined) {
        return;
      }
      if (this.filterCheck.length > 0) {
        clone = clone.filter(x =>
          this.filterCheck.includes(
            this.findInfoFromId(this.product, x.product_id).resource_type_id,
          ),
        );
      }

      if (this.filterArray.length > 0) {
        const check = this.filterArray.map(x => x.id);
        if (this.radioList.check_include) {
          clone = this.productLedgerList.filter(x =>
            check.includes(x.product_id),
          );
        } else if (this.radioList.check_exclude) {
          clone = this.productLedgerList.filter(
            x => !check.includes(x.product_id),
          );
        } else if (this.radioList.check_all) {
          clone;
        } else {
          return [];
        }
      }

      const obj = clone
        .sort(
          (a, b) =>
            Number(
              a.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ) -
            Number(
              b.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            ),
        )
        .reduce(function(rv, x) {
          (rv[x['input_date']] = rv[x['input_date']] || []).push(x);
          return rv;
        }, {});
      let arr = [];
      let count = 100000;
      const reducer = (accumulator, currentValue) =>
        accumulator + Number(currentValue.toFixed(6));
      for (let k in obj) {
        arr.push({
          date: k,
          month: k.substr(0, 7),
          element: obj[k],
        });

        const objInTemp = obj[k]
          .filter(
            x =>
              x.product_in_out_type_id ==
                this.productInOutType.find(x => x.detail == 'process in').id ||
              x.product_in_out_type_id ==
                this.productInOutType.find(x => x.detail == 'return in').id,
          )
          .map(x => x.quantity);

        const objOutTemp = obj[k]
          .filter(
            x =>
              x.product_in_out_type_id ==
                this.productInOutType.find(x => x.detail == 'using out').id ||
              x.product_in_out_type_id ==
                this.productInOutType.find(x => x.detail == 'sales out').id,
          )
          .map(x => x.quantity);

        const sumInQuantity = Number(objInTemp.reduce(reducer, 0).toFixed(6));
        const sumOutQuantity = Number(objOutTemp.reduce(reducer, 0).toFixed(6));
        obj[k].push({
          sum_date: '일 합계',
          input_date: k,
          id: count++,
          in_quantity: sumInQuantity,
          out_quantity: sumOutQuantity,
        });
      }

      return arr;
    },
    group_month() {
      const obj = this.group_day.reduce(function(rv, x) {
        (rv[x['month']] = rv[x['month']] || []).push(x);
        return rv;
      }, {});
      let arr = [];
      let count = 9999999;
      for (let k in obj) {
        arr.push({
          date: k,
          element: obj[k],
        });
        const day_list = obj[k]
          .map(x => x.element)
          .reduce((a, b) => a.concat(b))
          .filter(x => x.sum_date !== undefined);
        const sumAllInQuantity = day_list
          .map(x => x.in_quantity)
          .reduce((a, b) => this.$decimalAdd(a, b));
        const sumAllOutQuantity = day_list
          .map(x => x.out_quantity)
          .reduce((a, b) => this.$decimalAdd(a, b));
        obj[k].push({
          element: {
            sum_date: '월 합계',
            input_date: k + '-00',
            id: count++,
            in_quantity: sumAllInQuantity,
            out_quantity: sumAllOutQuantity,
          },
        });
      }
      return arr;
    },
    display_arr() {
      const temp = this.group_month.map(x => x.element);
      let list_ = [];

      temp.forEach(el => {
        el.forEach(x => {
          list_ = list_.concat(x.element);
        });
      });

      let reverse = list_.reverse();
      let curr_stock_temp = this.lodash.clonedeep(this.product_stock);
      reverse.forEach(x => {
        if (x.sum_date == undefined) {
          if (
            x.product_in_out_type_id ==
              this.productInOutType.find(x => x.detail == 'process in').id ||
            x.product_in_out_type_id ==
              this.productInOutType.find(x => x.detail == 'return in').id
          ) {
            x.curr_stock = Number(Number(curr_stock_temp).toFixed(3));
            curr_stock_temp -= x.quantity;
          } else {
            x.curr_stock = Number(Number(curr_stock_temp).toFixed(3));
            curr_stock_temp += x.quantity;
          }
        } else {
          x.curr_stock = Number(Number(curr_stock_temp).toFixed(3));
        }
      });

      reverse.push({
        curr_stock: Number(Number(curr_stock_temp).toFixed(3)),
        input_date: this.startDate,
        sum_date: '전 재고',
      });
      // reverse.forEach(element => {
      //   element.curr_stock = makeCommaNotZero(element.curr_stock);
      //   element.quantity = makeCommaNotZero(element.quantity);
      //   element.in_quantity = makeCommaNotZero(element.in_quantity);
      //   element.out_quantity = makeCommaNotZero(element.quantity);

      // });
      return reverse
        .reverse()
        .filter(
          x =>
            Number(x.input_date.replace(/-/gi, '')) <=
            Number(this.endDate.replace(/-/gi, '')),
        );
    },
    product_stock() {
      if (this.isFilterTable) {
        if (
          this.total_stock.find(x => x.id == this.filterArray[0].id) !=
          undefined
        ) {
          return this.total_stock.find(x => x.id == this.filterArray[0].id)
            .stock_quantity;
        } else return 0;
      } else {
        return 0;
      }
    },
    group_product() {
      let clone = this.lodash.clonedeep(this.display_arr);
      const obj = clone.reduce(function(rv, x) {
        (rv[x['sales_company']] = rv[x['sales_company']] || []).push(x);
        return rv;
      }, {});
      console.log(obj);
      let list_ = [];
      for (let k in obj) {
        if (k != 'undefined' && k != 'null') {
          list_.push({
            sales_company: k,
            count: obj[k].length,
          });
        }
      }
      return list_.sort((a, b) => b.count - a.count);
    },
  },
  methods: {
    openModal() {
      this.isLedgerModalOpen = true;
    },
    closeModal() {
      let ledgerTableClone = document.querySelector(
        '.ledger_print_modal .modal_body',
      );
      let ledgerTable = ledgerTableClone.querySelector('.product_print_table');
      if (ledgerTable) {
        ledgerTable.parentNode.removeChild(ledgerTable);
      }

      this.isLedgerModalOpen = false;
    },
    setStartDate(e) {
      if (e.target.value != '') {
        this.$store.commit('setStartDateToProductLedger', e.target.value);
        this.FETCH_PRODUCT_LEDGER();
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToProductLedger', yyyymmdd(new Date()));
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        this.$store.commit('setEndDateToProductLedger', e.target.value);
        this.FETCH_PRODUCT_LEDGER();
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToProductLedger', yyyymmdd(new Date()));
      }
    },
    setCheckedBox(rs_type_id) {
      if (!this.filterCheck.includes(rs_type_id)) {
        this.filterCheck.push(rs_type_id);
      } else {
        this.filterCheck.forEach((x, index) => {
          if (x == rs_type_id) {
            this.filterCheck.splice(index, 1);
          }
        });
        // const indexTemp = this.filterCheck.findIndex(rs_type_id);
      }
      // this.$store.commit('setFilterCheckToProductLedger', []);
      console.log(rs_type_id);
    },
    setCheckedRadio(checked) {
      this.$store.commit('setCheckedRadioToProductLedger', checked);
      this.$store.commit('setFilterArrayToProductLedger', []);
    },
    selectedRadio(arg) {
      this.filterArray.push(arg);
      this.showResourceSearch = false;
    },
    deletedSelectList() {
      this.delete_btn = false;
    },
    getPrimaryCompanyName(arr) {
      if (arr.length < 1) return '';

      let hitValue = this.companys.find(x => x.id == arr[0].company_id);
      return hitValue == undefined
        ? ''
        : hitValue.name +
            (arr.length > 1 ? ` 외 ${arr.length - 1}개 업체` : '');
    },
    getUnitName(index) {
      if (
        this.display_arr[index] != undefined &&
        this.display_arr[index].product_in_out_type_id != undefined
      ) {
        const UnitTemp = this.display_arr[index].unit_id;
        if (UnitTemp !== undefined) {
          const findUId = this.findInfoFromId(this.unitCodes, UnitTemp);
          return findUId != '' ? findUId.name : '';
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
    FETCH_PRODUCT_LEDGER() {
      this.$store
        .dispatch('FETCH_PRODUCT_LEDGER', {
          start: this.startDate,
          end: '2500-01-01',
        })
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '제품원장 정보를 불러오는 중 오류가 발생했습니다.',
          );
        })
        .finally(() => {});
    },
    trackingLotNum(lotNum) {
      const goingPage = routes[0].children.find(
        x => x.path == '/product/tracking',
      );
      if (goingPage != undefined) {
        goingPage.meta.select = Number(lotNum.substr(8, 5));
      }
      this.$router.push('/product/tracking');
    },
    addProduct(index) {
      if (this.filterArray != undefined && this.filterArray.length > 0) {
        const inclued_product_list = this.filterArray.map(x => x.id);
        return this.findInfoFromId(this.product, inclued_product_list[index])
          .name;
      } else {
        return '';
      }
    },
    exportExcel() {
      let table = document.querySelector('#excel_table');
      let button = table.querySelectorAll('button');
      button.forEach(el => {
        el.innerText = '';
      });
      let styles = `<style>
          #excel_table{width: 100%;border: 1px solid #ddd;}
          tr:first-child,tr:nth-child(2),tr:nth-child(3) {display:block;}
          tr.table_title th {background-color: #f6fafd;border-top: 1px solid #ddd;border-left: 1px solid #ddd;}
          tbody td {border-top: 1px solid #ddd;border-left: 1px solid #ddd;}
          #right{text-align: right;}
          tbody td:last-child {border-right: 1px solid #ddd;}
          tbody tr:last-child td {border-bottom: 1px solid #ddd;}
          tbody tr.default td {background-color: #f0f7fc;}
          tbody tr.day td {background-color: #f0ffee;}
          tbody tr.month td {background-color: #fffbd6;}
        </style>`;

      let uri = 'data:application/vnd.ms-excel;base64,';
      let template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->' +
        styles +
        '</head><body>{table}</body></html>';
      let base64 = function(s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      };
      let format = function(s, c) {
        return s.replace(/{(\w+)}/g, function(m, p) {
          return c[p];
        });
      };
      let ctx = {
        table: table.outerHTML.split('₩').join(''),
      };
      let a = document.createElement('a');
      a.href = uri + base64(format(template, ctx));
      a.download = `${
        this.filterArray.length == 1
          ? this.findInfoFromId(this.product, this.filterArray[0].id).name
          : `${
              this.findInfoFromId(this.product, this.filterArray[0].id).name
            }외${this.filterArray.length - 1}개`
      }_${this.startDate}~${this.endDate}.xls`;

      a.click();
      button.forEach(el => {
        el.innerText = '추적';
      });
    },
  },
  async created() {
    if (this.startDate == null) {
      var date = new Date();
      this.$store.commit('setEndDateToProductLedger', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 7));
      this.$store.commit('setStartDateToProductLedger', yyyymmdd(date2));
    }
    await this.FETCH('FETCH_PRODUCT_IN_OUT_TYPE', '제품 입출고 유형');
    await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    await this.FETCH('FETCH_UNIT', '단위');
    await this.FETCH('FETCH_COMPANY', '납품처');
    await this.FETCH('FETCH_RESOURCE_TYPE', '제품 종류');
    await this.FETCH('FETCH_PRODUCT_TOTAL_STOCK', '제품 재고');

    await this.FETCH_PRODUCT_LEDGER();

    if (this.filterCheck.length < 2) {
      this.filterCheck.push(
        this.resource_types.find(x => x.detail == 'product').id,
      );
    }
  },
};
</script>

<style lang="scss" scoped>
.selected_item {
  transition: all 0.5s;
}
.selected-enter,
.selected-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.selected-leave-active {
  position: absolute;
}
</style>
